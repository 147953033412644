import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { RouteHandlerReturn } from 'src/types/model';

import { logger, updateError } from 'lib/logger';

import Page from './[[...path]]';
import ErrorPage from './_error';

const Page404 = (): JSX.Element => {
  const [pageProps, setPageProps] = useState<RouteHandlerReturn>();
  const [isLoaded, setIsLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const getPageProps = async () => {
      try {
        const url = `/api/pages/not-found`;
        const response = await fetch(url);
        const props = (await response.json()) as RouteHandlerReturn;

        if (props?.props) {
          setPageProps(props);
        } else {
          throw new Error('Unable to fetch not-found page');
        }
      } catch (error) {
        if (error instanceof Error) {
          logger.error(updateError(error, { message: '404.tsx' }));
        }
      } finally {
        setIsLoaded(true);
      }
    };

    getPageProps();
  }, [router.locale]);

  if (isLoaded) {
    return pageProps ? <Page {...pageProps} /> : <ErrorPage />;
  }
  return <></>;
};

export default Page404;
